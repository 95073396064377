.menu {
  top: 0;
  position: absolute;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  inline-size: 100%;
  min-block-size: 100%;

  @media (max-width: 1400px) {
    min-block-size: 0;
    top: var(--header-height);
    background: rgb(var(--color-surface-container-lowest));
  }


  @media (max-width: 1400px) {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.5s ease-in-out;

    &.active {
      grid-template-rows: 1fr;
    }
  }
}
hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 1px solid rgb(var(--color-outline-variant));
  padding: 0;
  margin: 0;


  @media (max-width: 1400px) {
    margin-block: clamp(16px, 1.48svi, 24px);
  }
}

.overflow-hidden {

  @media (max-width: 1400px) {
    overflow: hidden;

    .overflow-content {
      padding-block: clamp(16px, 1.48svi, 24px);
      padding-inline: clamp(12px, 10svi, 48px);
      box-sizing: border-box;
    }
  }
}
