:root {
  --color-focus-visible: 0 0 0;
  --color-primary: 119 90 11;
  --color-surface-tint: 119 90 11;
  --color-on-primary: 255 255 255;
  --color-primary-container: 255 223 158;
  --color-on-primary-container: 38 26 0;
  --color-secondary: 107 93 63;
  --color-on-secondary: 255 255 255;
  --color-secondary-container: 245 224 187;
  --color-on-secondary-container: 36 26 4;
  --color-tertiary: 74 101 71;
  --color-on-tertiary: 255 255 255;
  --color-tertiary-container: 204 235 196;
  --color-on-tertiary-container: 7 33 9;
  --color-error: 186 26 26;
  --color-on-error: 255 255 255;
  --color-error-container: 255 218 214;
  --color-on-error-container: 65 0 2;
  --color-success: 34 139 34;
  --color-on-success: 255 255 255;
  --color-success-container: 204 255 204;
  --color-on-success-container: 0 100 0;
  --color-background: 255 248 242;
  --color-on-background: 31 27 19;
  --color-surface: 255 248 242;
  --color-on-surface: 31 27 19;
  --color-surface-variant: 237 225 207;
  --color-on-surface-variant: 77 70 57;
  --color-outline: 127 118 103;
  --color-outline-variant: 208 197 180;
  --color-shadow: 0 0 0;
  --color-scrim: 0 0 0;
  --color-inverse-surface: 53 48 39;
  --color-inverse-on-surface: 249 239 226;
  --color-inverse-primary: 233 193 108;
  --color-primary-fixed: 255 223 158;
  --color-on-primary-fixed: 38 26 0;
  --color-primary-fixed-dim: 233 193 108;
  --color-on-primary-fixed-variant: 91 67 0;
  --color-secondary-fixed: 245 224 187;
  --color-on-secondary-fixed: 36 26 4;
  --color-secondary-fixed-dim: 216 196 160;
  --color-on-secondary-fixed-variant: 82 69 42;
  --color-tertiary-fixed: 204 235 196;
  --color-on-tertiary-fixed: 7 33 9;
  --color-tertiary-fixed-dim: 176 207 170;
  --color-on-tertiary-fixed-variant: 51 77 49;
  --color-surface-dim: 226 217 204;
  --color-surface-bright: 255 248 242;
  --color-surface-container-lowest: 255 255 255;
  --color-surface-container-low: 252 242 229;
  --color-surface-container: 246 236 223;
  --color-surface-container-high: 241 231 217;
  --color-surface-container-highest: 235 225 212;
  --shadow-elevation-1: 0px 1px 2px 0px rgb(0 0 0 / 30%), 0px 1px 3px 1px rgb(0 0 0 / 15%);
  --shadow-elevation-2: 0px 1px 2px 0px rgb(0 0 0 / 30%), 0px 2px 6px 2px rgb(0 0 0 / 15%);
  --shadow-elevation-3: 0px 1px 3px 0px rgb(0 0 0 / 30%), 0px 4px 8px 3px rgb(0 0 0 / 15%);
}
