.button {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  border-radius: 25px;

  .value {
    padding-inline: 15px;
  }

  .icon {
    font-size: 1.25rem;
    padding-inline-start: 15px;
    display: flex;
    align-items: center;

    &:only-child {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;
      height: 20px;
      aspect-ratio: 1;

      @media (max-width: 1400px) {
        height: 35px;
      }
    }
  }

  .backdrop {
    width: 100%;
    padding: 10px;
    display: flex;
  }

  &[data-variant="filled"] {
    color: rgb(var(--color-on-primary));
    background: rgb(var(--color-primary));

    &:disabled {
      color: rgba(var(--color-on-surface) / 12%);
      background: rgba(var(--color-on-surface) / 12%);
    }

    &:hover .backdrop {
      background: rgba(var(--color-on-primary) / 8%);
    }

    &[data-active="true"] .backdrop {
      background: rgba(var(--color-on-primary) / 8%);
    }
  }

  &[data-variant="outlined"] {
    border: 2px solid rgb(var(--color-outline));
    color: rgb(var(--color-on-surface-variant));

    .backdrop {
      padding: 8px;
    }

    &:disabled {
      border: 2px solid rgba(var(--color-outline) / 12%);
      color: rgba(var(--color-on-surface) / 12%);
    }

    &:not(:disabled):hover .backdrop {
      background: rgba(var(--color-primary) / 8%);
    }

    &[data-active="true"] .backdrop {
      background: rgba(var(--color-primary) / 12%);
    }
  }

  &[data-variant="text"] {
    color: rgb(var(--color-primary));


    &:disabled {
      color: rgba(var(--color-on-surface) / 12%);
    }

    &:not(:disabled):hover .backdrop {
      background: rgba(var(--color-primary) / 8%);
    }

    &[data-active="true"] .backdrop {
      background: rgba(var(--color-primary) / 12%);
    }
  }

  &[data-variant="elevated"] {
    color: rgb(var(--color-primary));
    background: rgb(var(--color-surface-container-low));
    //box-shadow: var(--shadow-elevation-1);


    &:disabled {
      color: rgba(var(--color-on-surface) / 12%);
      background: rgba(var(--color-on-surface) / 12%);
      box-shadow: none;
    }

    &:not(:disabled):hover .backdrop {
      background: rgba(var(--color-primary) / 8%);
    }

    &[data-active="true"] .backdrop {
      background: rgba(var(--color-primary) / 12%);
    }
  }

  &[data-variant="toned"] {
    color: rgb(var(--color-on-secondary-container));
    background: rgb(var(--color-secondary-container));

    &:not(:disabled):hover {
      box-shadow: var(--shadow-elevation-1);
     .backdrop {
       background: rgba(var(--color-on-secondary-container) / 8%);
     }
    }
  }

  &:focus-visible {
    box-shadow: 0 0 0 2.5px #d2e3fc;
    outline: 2.5px solid rgb(var(--color-focus-visible));
  }
}