.spinner-wrapper {
  width: 100%;
  aspect-ratio: 19/8;

  .spinner {
    border: 4px solid rgb(var(--color-primary-container));
    border-top: 4px solid rgb(var(--color-primary));
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}