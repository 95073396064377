// Import Google Fonts
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@400;700&family=Open+Sans:wght@300;400;700&display=swap');

// Base font sizes
$base-font-size: 1rem;
$body-font-size: $base-font-size;

// Font families
$font-family-headlines: 'Dosis', sans-serif;
$font-family-body: 'Open Sans', sans-serif;

// Text styles mixin
@mixin text-style($font-family, $font-size, $font-weight: normal, $line-height: 1.5, $margin: 0, $padding: 0) {
  font-family: $font-family;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  margin: $margin;
  padding: $padding;
}

// Headline sizes
$h1-size: $base-font-size * 3;
$h2-size: $base-font-size * 2.5;
$h3-size: $base-font-size * 2;
$h4-size: $base-font-size * 1.75;
$h5-size: $base-font-size * 1.5;
$h6-size: $base-font-size * 1.25;

// Responsive adjustments for computer screens
@mixin responsive-text-styles {
  @media (min-width: 1024px) {
    .h1 {
      font-size: $h1-size * 1.25;
    }
    .h2 {
      font-size: $h2-size * 1.25;
    }
    .h3 {
      font-size: $h3-size * 1.25;
    }
    .h4 {
      font-size: $h4-size * 1.25;
    }
    .h5 {
      font-size: $h5-size * 1.25;
    }
    .h6 {
      font-size: $h6-size * 1.25;
    }
  }
}

// Headline styles
h1 {
  @include text-style($font-family-headlines, $h1-size, bold);
}

h2 {
  @include text-style($font-family-headlines, $h2-size, bold);
}

h3 {
  @include text-style($font-family-headlines, $h3-size, bold);
}

h4 {
  @include text-style($font-family-headlines, $h4-size, bold);
}

h5 {
  @include text-style($font-family-headlines, $h5-size, bold);
}

h6 {
  @include text-style($font-family-headlines, $h6-size, bold);
}

// Body text styles
p, span, li, a, div, td {
  @include text-style($font-family-body, $body-font-size);
}

// Utility classes for headline sizes
.h1 {
  @include text-style($font-family-body, $h1-size);
  @include responsive-text-styles;
}

.h2 {
  @include text-style($font-family-body, $h2-size);
  @include responsive-text-styles;
}

.h3 {
  @include text-style($font-family-body, $h3-size);
  @include responsive-text-styles;
}

.h4 {
  @include text-style($font-family-body, $h4-size);
  @include responsive-text-styles;
}

.h5 {
  @include text-style($font-family-body, $h5-size);
  @include responsive-text-styles;
}

.h6 {
  @include text-style($font-family-body, $h6-size);
  @include responsive-text-styles;
}

// Classes for font weight variations
.bold {
  font-weight: bold;
}

.light {
  font-weight: 300;
}

.headline-font-family {
  font-family: $font-family-headlines;
}

.color-primary {
  color: rgb(var(--color-primary));
}

.color-success {
  color: rgb(var(--color-success))
}

.color-error {
  color: rgb(var(--color-error))
}

.capitalize {
  text-transform: capitalize;
}