.header {
  z-index: 1;
  display: flex;
  position: sticky;
  top: 0;
  background: rgb(var(--color-surface-container-low));
  height: var(--header-height);

  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 1400px) {
      position: static;
    }
  }
}

