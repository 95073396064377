.dropdown-content {
  z-index: 10;
  position: absolute;
  right: 0;
  box-sizing: border-box;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 1rem;
  padding: 1rem;
  margin-block-start: 0.5rem;
  background-color: rgb(var(--color-surface-container-lowest));
  border-radius: calc(12px + clamp(12px, 1.48svi, 24px));
  max-height: 40vh;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  transform: translateY(-5%);
  transition: transform 150ms ease-in-out, opacity 100ms ease-in-out;
  opacity: 0;
  pointer-events: none;

  & > * {
    display: flex;
    width: 100%;
  }
}

.dropdown-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.content-open {
  transform: translateY(0);
  opacity: 1;
  pointer-events: all;
}