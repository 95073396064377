@use "sass:math";

.toolbar {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: flex-end;
  padding-inline-start:  calc(var(--header-height) * math.tan(15deg));
  padding-inline-end: 0.5rem;
  padding-block: 0.2rem;

  @media (max-width: 1400px) {
    justify-content: center;
    padding: 0;
  }
}