.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: rgb(var(--color-on-surface-variant));
  stroke-width:5.5;
  stroke-linecap:round;
}

.hamburger {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .top {
    stroke-dasharray: 40 160;
  }
  .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }

  &.active {

    .top {
      stroke-dashoffset: -64px;
    }
    .middle {
      transform: rotate(90deg);
    }
    .bottom {
      stroke-dashoffset: -64px;
    }
  }
}

.hamburger-rotate {
  &.active {
    transform: rotate(45deg);
  }
}

.hamburger-button.button {
  display: none;
  margin-inline: clamp(8px, 2.5svi, 24px);

  @media (max-width: 1400px) {
    display: block;
  }
}

