.logo-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  background: #ffc107;

  img {
    z-index: 2;
    padding-inline: 25px;
    height: 50%;
  }

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    transform: skewX(-15deg);
    transform-origin: bottom;
  }
}