.navbar {
  display: flex;

  @media (max-width: 1400px) {
    justify-content: center;
  }

  @media (max-width: 1400px) {
    padding-inline: clamp(16px, 1.48svi, 24px);
    display: block;
  }

  .nav-link {
    z-index: 2;
    position: relative;
    display: flex;
    align-items: stretch;
    color: rgb(var(--color-on-surface-variant));
    transform: skewX(-15deg);
    transform-origin: bottom;

    .backdrop {
      display: flex;
      align-items: center;
      width: 100%;
    }

    .value {
      padding-inline: 2rem;
      transform: skewX(15deg);
      transform-origin: bottom;
      font-family: "Dosis", sans-serif;
      font-optical-sizing: auto;
      font-size: 1.3rem;
      font-weight: 600;
      font-style: normal;
      padding-block: 11px;
    }

    &.active {
      color: rgb(var(--color-on-secondary-container));
      background: rgb(var(--color-secondary-container));
    }

    &:hover .backdrop {
      background: rgba(var(--color-on-surface-variant) / 8%);
    }

    &.active:hover .backdrop {
      background: rgba(var(--color-on-secondary-container) / 8%);
    }

    &:focus-visible {
      outline: 2.5px solid rgb(var(--color-focus-visible));
      border-radius: 4px;
    }
  }
}