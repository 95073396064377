@import "tokens";
@import "fonts";

body {
  background: rgb(var(--color-background));
}

a,
button {
  all: unset;
}

.layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(1, 1fr);
  padding: clamp(8px ,2.5svi, 24px);
  gap: clamp(8px ,2.5svi, 24px);
}

.grid {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(1, 1fr);
  gap: clamp(8px ,2.5svi, 24px);
}

@for $i from 12 through 1 {
  .g-col-#{$i} {
    grid-column-end: span $i;
  }
}

.align-text-right {
  text-align: right;
}

.align-text-center {
  text-align: center;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: flex-end;
}

.flex-column {
  display: flex;
  flex-flow: column;
  gap: clamp(12px, 1.48svi, 24px);
}

.gap {
  gap: clamp(12px, 1.48svi, 24px);
}

.pad-top {
  padding-top: 20px;
}

.d-grid {
  display: grid;
}

.g-cols-2 {
  grid-template-columns: repeat(2, auto);

  @media (max-width: 1100px) {
    grid-template-columns: repeat(1, auto);
  }
}

.g-cols-3 {
  grid-template-columns: repeat(3, auto);
}

//Special otvaracia doba
.g-cols-4 {
  gap: 8px;
  grid-template-columns: 1fr repeat(3, auto);
}

@for $x from 1 through 12 {
  .g-start-#{$x} {
    grid-column-start: #{$x};
  }

  .g-col-span-#{$x} {
    grid-column-end: span #{$x};
  }

  .g-row-span-#{$x} {
    grid-row-end: span #{$x};
  }
}

@media (max-width: 950px) {
  .g-col-span-4,
  .g-col-span-8 {
    grid-column: span 6;
  }

  .g-col-span-8 {
    grid-row-end: 3;
    grid-column-end: 13;
  }
}

@media (max-width: 950px) {
  .g-col-span-4,
  .g-col-span-8 {
    grid-column: span 12;
  }
  .g-col-span-8 {
    grid-row-end: 5;
  }
}