@media (prefers-color-scheme: dark) {
  :root {
    --color-focus-visible: 0 0 0;
    --color-primary: 233 193 108;
    --color-surface-tint: 233 193 108;
    --color-on-primary: 63 46 0;
    --color-primary-container: 91 67 0;
    --color-on-primary-container: 255 223 158;
    --color-secondary: 216 196 160;
    --color-on-secondary: 58 47 21;
    --color-secondary-container: 82 69 42;
    --color-on-secondary-container: 245 224 187;
    --color-tertiary: 176 207 170;
    --color-on-tertiary: 29 54 28;
    --color-tertiary-container: 51 77 49;
    --color-on-tertiary-container: 204 235 196;
    --color-error: 255 180 171;
    --color-on-error: 105 0 5;
    --color-error-container: 147 0 10;
    --color-on-error-container: 255 218 214;
    --color-success: 50 205 50;
    --color-on-success: 0 50 0;
    --color-success-container: 0 100 0;
    --color-on-success-container: 204 255 204;
    --color-background: 23 19 11;
    --color-on-background: 235 225 212;
    --color-surface: 23 19 11;
    --color-on-surface: 235 225 212;
    --color-surface-variant: 77 70 57;
    --color-on-surface-variant: 208 197 180;
    --color-outline: 153 143 128;
    --color-outline-variant: 77 70 57;
    --color-shadow: 0 0 0;
    --color-scrim: 0 0 0;
    --color-inverse-surface: 235 225 212;
    --color-inverse-on-surface: 53 48 39;
    --color-inverse-primary: 119 90 11;
    --color-primary-fixed: 255 223 158;
    --color-on-primary-fixed: 38 26 0;
    --color-primary-fixed-dim: 233 193 108;
    --color-on-primary-fixed-variant: 91 67 0;
    --color-secondary-fixed: 245 224 187;
    --color-on-secondary-fixed: 36 26 4;
    --color-secondary-fixed-dim: 216 196 160;
    --color-on-secondary-fixed-variant: 82 69 42;
    --color-tertiary-fixed: 204 235 196;
    --color-on-tertiary-fixed: 7 33 9;
    --color-tertiary-fixed-dim: 176 207 170;
    --color-on-tertiary-fixed-variant: 51 77 49;
    --color-surface-dim: 23 19 11;
    --color-surface-bright: 62 56 47;
    --color-surface-container-lowest: 17 14 7;
    --color-surface-container-low: 31 27 19;
    --color-surface-container: 35 31 23;
    --color-surface-container-high: 46 41 33;
    --color-surface-container-highest: 57 52 43;
    --shadow-elevation-1: none;
    --shadow-elevation-2: none;
    --shadow-elevation-3: none;
  }
}
